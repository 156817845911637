export default () => ({
    currencyAndIndexingUnitByCompany: [],
    activitiesByProjectToGridAuditedByLeader: [],
    grid: {
        id: '',
    },
    activitiesByProjectToGridAuditedByLeaderDetail: {
        date: '01/01/2022',
        clientName: '',
        numberOfActivities: 0,
        tradeAgreementType: 0,
        billableHours: 0,
        activities: [],
    },
    operativeActivityByUserIdToBillingNotes: null,
    operativeActivityByUserNameToBillingNotes: null,
    OABUAuditedByLeaderIdArray: [],
    showSideTab: false,
    blocksToSettle: [],
    gridSummaryData: [],
    gridSummaryComponent: null,
    preinvoice: null,
    billing: null,
    rateBooks: [],
    invoicesByClient: [],
    gridSummaryFeeEffectiveByHour: 0,
    unitOfPaymentConvention: '',
    formatCurrencyShow: '#,##0.#######',
    formatCurrencyShowModified: '',
    infoUnitIndexingValuesGlobal: null,
    aditionalComments: '',
    OABUAuditedByLeaderIndicator: 0,
    OABUAwaitingPreinvoiceBlocksIndicator: 0,
    PreinvoiceByProjectIndicator: 0,
    BillingIndicator: 0,
    activitiesSummary: [],
    newBillingNote: false,
    monthOfPreinvoice: null,
    cancelProcessFlag: false,
    sendActivitiesToPreinvoiceFlag: false,
    sendActivitiesToPreinvoiceInterFlag: false,
    sendBlocksFlag: false,
    sendBlockSeparateFlag: false,
    sendBlocksInterFlag: false,
    sendBlockSeparateInterFlag: false,
    shutDownBlocksOnlyFlag: false,
    shutDownBlocksFlag: false,
    aprovePreinvoiceOnlyFlag: false,
    aprovePreinvoiceFlag: false,
    moneyIndicators: null,
    preinvoiceByProjectMoneyIndicators: null,
    intervalsValues: null,
    resetPreinvoice: false,
    commentPreinvoice: '',
    invoicesByProject: [],
    cancelPreinvoiceFlag: false,
    cancelBillingFlag: false,
    tradeAgreementGridPDF: [],
    exportGridsFlag: false,
    exportGridInfo: [],
    clientsForDropdown: [],
    changeFromGrid: false,
    creditNoteId: null,
    cashRegisterReceiptId: null,
    noovaState: null,
    infoLogicBlocksState: null,
    stateModal: false,
    setInfoModal: {},
    updateActivities: false,
    keySelectedInModal: null,
    reloadFatherByModal: false,
    valueCreateNc: false,
    sendBlockSeparateFlagInModal: false,
    infoMoney: [],
    selectedRows: [],
    headerModal: {},
});
