export default () => ({
    stateMenu: false,
    loading: true,
    currentPage: '',
    organization: {
        id: null,
        name: '',
    },
    stepForm: 1,
    company: JSON.parse(localStorage.getItem('company')) ?? {},
    companyInfo: {
        companyId: localStorage.getItem('companyId'),
        companyName: localStorage.getItem('companyActiveName'),
        companyProcess: localStorage.getItem('companyProcess'),
    },
    generalCountries: [],
    showPopup: false,
    showPopup1: false,
    showPopup2: false,
    showSideTab: false,
    chronometer: {
        seconds: '0',
        minutes: '0',
        hours: '0',
        state: 'stop',
    },
    chronometerState: localStorage.getItem('statePlayChronometer') ?? 'stop',
    tenantsByUser: [],
    registerForm: {},
    language: 1,
    dataGridComponentInfo: {},
    dataToSideTab: null,
    templateSideTab: '',
    clientData: {
        name: '',
        comercialName: '',
        nit: '',
        direction: '',
        phone: '',
        contacts: '',
    },
    projectData: {
        name: '',
        area: '',
        subArea: '',
        responsable: '',
        workgroup: '',
    },
    caseData: {
        name: '',
        description: '',
    },
    mapcountries: {},
    wizardTitle: true,
    stateNotify: [],
    showProfileCard: false,
    UploadIdAWS: [],
    PrevETagsAWS: [],
    chunksAWS: [],
    IdUploadFileS3: null,
    fromDate: null,
    untilDate: null,
    workActive: null,
    showWalkDashboard: false,
    // showWalkDashboardNT: localStorage.getItem('showWalkDashboardNT')
    //     ? localStorage.getItem('showWalkDashboardNT')
    //     : true,
    showWalkDashboardNT: false,
    showWalkFlow: false,
    showCustomPopup: false,
    templateCustomPopup: '',
    exitForm: false,
    // showWalkFlowNT: localStorage.getItem('showWalkFlowNT')
    //     ? localStorage.getItem('showWalkFlowNT')
    //     : true,
    showWalkFlowNT: false,
    showWalkCalendar: false,
    // showWalkCalendarNT: localStorage.getItem('showWalkCalendarNT')
    //     ? localStorage.getItem('showWalkCalendarNT')
    //     : true,
    showWalkCalendarNT: false,
    showWalkActivities: false,
    // showWalkActivitiesNT: localStorage.getItem('showWalkActivitiesNT')
    //     ? localStorage.getItem('showWalkActivitiesNT')
    //     : true,
    showWalkActivitiesNT: false,
    paramsCustomPopup: null,
    intervalChronometer: null,
    chronometerActivity: localStorage.getItem('idPlayChronometer') ?? null,
    verifyDeploy: localStorage.getItem('verifyDeploy') ?? true,
    idWorkFlowReport: null,
    showAssigned: true,
    showInProcess: true,
    showFinished: true,
    isExpandView: true,
    typeCalendarActivities: true,
    showWalkActivitiesCreate: false,
    // showWalkActivitiesCreateNT: localStorage.getItem(
    //     'showWalkActivitiesCreateNT',
    // )
    //     ? localStorage.getItem('showWalkActivitiesCreateNT')
    //     : true,
    showWalkActivitiesCreateNT: false,
    showWalkDashFlows: false,
    // showWalkDashFlowsNT: localStorage.getItem('showWalkDashFlowsNT')
    //     ? localStorage.getItem('showWalkDashFlowsNT')
    //     : true,
    showWalkDashFlowsNT: false,
    localPermissions: {},
    seletedTeam: JSON.parse(localStorage.getItem('seletedTeam')) ?? [],
    seletedUser: JSON.parse(localStorage.getItem('seletedUser')) ?? [],
    seletedAreas: JSON.parse(localStorage.getItem('seletedAreas')) ?? [],
    seletedSubareas: JSON.parse(localStorage.getItem('seletedSubareas')) ?? [],
    seletedClients: JSON.parse(localStorage.getItem('seletedClients')) ?? [],
    deleteFilter: false,
    applyFilter: false,
    userCompanies: [],
    changeCompany: (localStorage.getItem('changeCompany') === 'false' || localStorage.getItem('changeCompany') === null) ? false : true,
    casetData: {},
    companyImage: false,
    S3UrlDocument: null,
    nameS3UrlDocument: null,
    showPopupSessionUser: false,
    showSideTabCitation: false,
    userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    habilitySesionPopup: false,
    moduleTitle: '',
});
