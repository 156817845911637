<template>
    <!-- <div class="home" style="background-color: red"> -->
    <div class="home">
        <div class="content-container" style="position: relative">
            <router-view v-if="showRoutes" v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        async created() {
            this.preCarga();
            if (this.status !== 'authenticated' && !this.loading) {
                this.$auth0.loginWithRedirect();
            }
            this.setUser();
            let localTenant = this.tenantId
                ? this.tenantId
                : localStorage.getItem('tenantId');
            if (localTenant === undefined || localTenant === null) {
                await this.getTenantsByUser(this.user.email);
                if (this.tenantsByUser.length === 0) {
                    this.$router.push({ name: 'register' });
                } else if (this.tenantsByUser.length === 1) {
                    localStorage.setItem('tenantId', this.tenantsByUser[0].id);
                    this.$store.commit(
                        'auth/setTenantId',
                        this.tenantsByUser[0].id,
                    );
                    await this.getToken(this.$auth0);
                    await this.getGeneralInfoUSer();
                    let infoCompanyLocal = localStorage.getItem('company');
                    if (
                        infoCompanyLocal === null ||
                        infoCompanyLocal === undefined
                    ) {
                        await this.getGeneralInfo();
                    }
                    this.$store.commit(
                        'global/updateStateLoading',
                        this.$auth0.isLoading.value,
                    );
                } else {
                    this.$router.push({ name: 'select-tenant' });
                }
            } else {
                await this.getToken(this.$auth0);
                await this.getGeneralInfoUSer();
                let infoCompanyLocal = localStorage.getItem('company');
                if (
                    infoCompanyLocal === null ||
                    infoCompanyLocal === undefined
                ) {
                    await this.getGeneralInfo();
                }
                this.$store.commit(
                    'global/updateStateLoading',
                    this.$auth0.isLoading.value,
                );
                
                this.$store.commit(
                        'auth/setTenantId',
                        parseInt(localTenant),
                    );
            }
        },
        data() {
            return {
                tokenDecode: null,
                showRoutes: false,
            };
        },
        components: {
            // HeaderComponent: defineAsyncComponent(() =>
            //     import('../components/header/HeaderComponent.vue'),
            // ),
            // SideMenu: defineAsyncComponent(() =>
            //     import('../components/side-menu/SideMenu.vue'),
            // ),
        },
        computed: {
            ...mapState('auth', ['status', 'user', 'token', 'tenantId']),
            ...mapState('global', ['loading', 'tenantsByUser', 'companyInfo']),
        },
        methods: {
            ...mapActions('auth', ['getToken']),
            ...mapActions('management', ['getRolesById']),
            ...mapActions('global', [
                'getOrganizationsByEmailUser',
                'getGeneralInfoByEmail',
                'getGeneralInfo',
                'getTenantsByUser',
                'getCountries',
            ]),
            async preCarga() {
                if (this.token != null) {
                    this.showRoutes = true;
                } else {
                    setTimeout(() => {
                        this.preCarga();
                    }, 5000);
                }
            },
            setUser() {
                this.$store.commit('auth/setUser', [
                    this.$auth0.user,
                    this.$auth0.isAuthenticated,
                    this.$auth0.error,
                ]);
            },
            async getGeneralInfoUSer() {
                var response = await this.getGeneralInfoByEmail(
                    this.user.email,
                );
                if (response.success === true) {
                    if (response.data !== null && response.data !== undefined) {
                        localStorage.setItem('keyU', response.data.userId)
                        localStorage.setItem('utemp',response.data.userId)
                        this.$store.commit('auth/setUserDB', {
                            userId: response.data.userId,
                            username: response.data.username,
                            supervisor: response.data.supervisor,
                            workgroup: response.data.workgroup,
                            meWorkgroups: response.data.meWorkgroups,
                            category: response.data.category,
                            picture: response.data.picture,
                            workingEdition: response.data.workingEdition,
                            subAreas: response.data.subAreas,
                            areas: response.data.areas,
                            isSuperAdmin: response.data.isSuperAdmin
                        });
                        this.$store.commit('global/setCompanyInfo', {
                            companyId: response.data.companyId,
                            companyName: response.data.companyName,
                            typeBillableTime: response.data.typeBillableTime,
                            endDayDeliveryTime:
                                response.data.endDayDeliveryTime,
                            dailyWorkedHours: response.data.dailyWorkedHours,
                            workingDays: response.data.workingDays,
                            dailyHourRegistrationTimeLimit:response.data.dailyHourRegistrationTimeLimit,
                            initialWorkHour:response.data.initialWorkHour
                        });
                        await this.getRolesById(response.data.rol);
                        let localCompanyId = localStorage.getItem('companyId');
                        if (localCompanyId === null) {
                            localStorage.setItem(
                                'companyId',
                                response.data.companyId,
                            );
                        } else if (localCompanyId !== response.data.companyId) {
                            localStorage.setItem('companyId', localCompanyId);
                        } else {
                            localStorage.setItem(
                                'companyId',
                                response.data.companyId,
                            );
                        }
                    }
                }
            },
        },
    };
</script>

<style lang="scss">
    @import '../styles/styles.scss';
</style>
