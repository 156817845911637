import { mapActions, mapState } from 'vuex';
export default {
    data() {
        return {
            inactivityTimeout: null,
            timeoutDuration: 4 * 60 * 60 * 1000, // 4 horas // 1 * 60 * 1000 = 1 minuto
            channel: null,
            tabId: `tab-${Date.now()}-${Math.random()
                .toString(36)
                .substr(2, 9)}`,
            lastBroadcastTime: 0,
            sessionClosed: false,
        };
    },
    computed: {
        ...mapState('auth', ['userDB', 'user']),
    },
    methods: {
        ...mapActions('global', ['updateSessionByUser']),
        resetTimer() {
            if (this.sessionClosed) return;
            clearTimeout(this.inactivityTimeout);
            this.inactivityTimeout = setTimeout(
                this.handleInactivity,
                this.timeoutDuration,
            );

            const now = Date.now();
            if (now - this.lastBroadcastTime > 5000) {
                this.lastBroadcastTime = now;

                if (this.channel) {
                    this.channel.postMessage({
                        type: 'activity',
                        sender: this.tabId,
                    });
                    // console.log(
                    //     `[${this.tabId}] Enviando mensaje: actividad detectada.`,
                    // );
                }
            }

            // console.log(`[${this.tabId}] Temporizador reiniciado.`);
        },
        handleInactivity() {
            if (this.sessionClosed) return;
            this.sessionClosed = true;

            // console.log(`[${this.tabId}] Cierre de sesión por inactividad.`);

            if (this.channel) {
                this.channel.postMessage({
                    type: 'logout',
                    sender: this.tabId,
                });
                // console.log(
                //     `[${this.tabId}] Enviando mensaje: cierre de sesión.`,
                // );
            }
            this.logout();
        },
        handleChannelMessage(event) {
            const { type, sender } = event.data;

            // console.log(`[${this.tabId}] Mensaje recibido:`, event.data);

            if (sender === this.tabId) {
                // console.log(
                //     `[${this.tabId}] Mensaje ignorado (es de esta pestaña).`,
                // );
                return;
            }

            if (type === 'activity') {
                // console.log(
                //     `[${this.tabId}] Actividad detectada en otra pestaña. Reiniciando temporizador.`,
                // );
                this.resetTimer();
            } else if (type === 'logout') {
                if (this.sessionClosed) return;
                this.sessionClosed = true;

                // console.log(
                //     `[${this.tabId}] Cierre de sesión iniciado desde otra pestaña.`,
                // );
                this.logout();
            }
        },
        setupEventListeners() {
            if (!this.channel) {
                this.channel = new BroadcastChannel('activityChannel');
                this.channel.onmessage = this.handleChannelMessage;
                // console.log(
                //     `[${this.tabId}] Canal de comunicación inicializado.`,
                // );
            }

            window.addEventListener('mousemove', this.resetTimer);
            window.addEventListener('click', this.resetTimer);
            window.addEventListener('keydown', this.resetTimer);

            // console.log(`[${this.tabId}] Listeners configurados.`);
        },
        removeEventListeners() {
            if (this.channel) {
                this.channel.close();
                this.channel = null;
                // console.log(`[${this.tabId}] Canal de comunicación cerrado.`);
            }

            window.removeEventListener('mousemove', this.resetTimer);
            window.removeEventListener('click', this.resetTimer);
            window.removeEventListener('keydown', this.resetTimer);

            // console.log(`[${this.tabId}] Listeners eliminados.`);
        },
        async logout() {
            localStorage.removeItem('viewAs');
            localStorage.removeItem('utemp');
            localStorage.removeItem('showVideo');
            localStorage.removeItem('tenantId');
            localStorage.removeItem('picture');
            localStorage.removeItem('companyId');
            localStorage.removeItem('company');
            localStorage.removeItem('m_params');
            localStorage.removeItem('proyectActiveId');
            localStorage.removeItem('proyectActiveName');
            localStorage.removeItem('m_paramsWF');
            localStorage.removeItem('currentPage');
            localStorage.removeItem('autorized');
            localStorage.removeItem('razonesSocial');
            localStorage.removeItem('filterDash');
            localStorage.removeItem('areas');
            localStorage.removeItem('showWalkDashboardNT');
            localStorage.removeItem('showWalkActivitiesNT');
            localStorage.removeItem('showWalkFlowNT');
            localStorage.removeItem('companyActiveName');
            localStorage.removeItem('transversalFilter');
            localStorage.removeItem('changeCompany');
            localStorage.removeItem('keyU');
            localStorage.removeItem('seletedTeam');
            localStorage.removeItem('seletedUser');
            localStorage.removeItem('seletedAreas');
            localStorage.removeItem('seletedSubareas');
            localStorage.removeItem('seletedClients');
            localStorage.removeItem('selectedDate');
            localStorage.removeItem('issaveversion');
            localStorage.removeItem('selectCompanyManagementName');
            localStorage.removeItem('selectCompanyManagementId');
            localStorage.removeItem('lastVisitedRoute');
            localStorage.removeItem('limitSessionDate');
            const response = await this.updateSessionByUser(this.userDB.userId);
            if (response != null) {
                localStorage.removeItem('sessionId');
            }

            let Cookies = document.cookie.split(';');
            for (var i = 0; i < Cookies.length; i++) {
                document.cookie =
                    Cookies[i] + '=; expires=' + new Date(0).toUTCString();
            }
            localStorage.setItem('verifyDeploy', false);
            sessionStorage.clear();
            this.$auth0.logout();
        },
    },
    mounted() {
        // console.log(`[${this.tabId}] Componente montado. Inicializando...`);
        this.resetTimer();
        this.setupEventListeners();
    },
    beforeDestroy() {
        // console.log(
        //     `[${this.tabId}] Componente destruido. Limpiando recursos...`,
        // );
        clearTimeout(this.inactivityTimeout);
        this.removeEventListeners();
    },
};
