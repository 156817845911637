export default () => ({
    stateMenu: false,
    grid: null,
    withFilters: false,
    activities: {},
    configSchedulerViews: [],
    dataDashboard: null,
    metaRangeByUser: 0,
    cargandobtn: 1,
    listWorkFlows: [],
    listActivities: [],
    listCases: [],
    filterState: 4,
    generalFilterDash: JSON.parse(
        localStorage.getItem('transversalFilter'),
    ) ?? {
        unitservice: [],
        practices: [],
        can: [],
        oportunityStates: 4,
        page: 0,
        pageSize: 0,
        states: [],
        time: 4,
        completeActs: true,
        clientIds: [],
        subAreasIds: [],
        usersIds: [],
        onlyUsersIds: [],
        workgroup: [],
        company: localStorage.getItem('companyId'),
        groups: [],
        subareas: [],
        supervisor: [],
        starDate: new Date(),
        endDate: new Date(),
        typeTime: 1,
        Options: {
            requireTotalCount: true,
            searchOperation: 'contains',
            searchValue: null,
            skip: 0,
            take: 20,
            userData: {},
            sort: null,
            group: null,
            totalSummary: [],
        },
        clientTimeZone: null,
    },
    selectedTeam: [],
    selectedTeamCount: 0,
    teamsOnlyItems: [],
    selectedArea: [],
    selectedAreaCount: 0,
    areasOnlyItems: [],
    selectedCustomer: [],
    selectedCustomerCount: 0,
    customersOnlyItems: [],
    selectedTime: localStorage.getItem('selectedTime') ?? 'Mes',
    selectedDate: localStorage.getItem('selectedDate') ?? new Date(),
    teams: JSON.parse(localStorage.getItem('teams')) ?? [],
    areas: JSON.parse(localStorage.getItem('areas')) ?? [],
    razonesSocial: JSON.parse(localStorage.getItem('razonesSocial')) ?? [],
    totalSearchActivities: null,
    timeBillableIndication: 0,
    timeNoBillableIndication: 0,
    historyOportunityIndication: 0,
    assigmentHoursIndication: 0,
    workedsHoursIndication: 0,
    awaitingRevisionHoursIndication: 0,
    efficiencyIndication: 0,
    completeActivitiesIndication: 0,
    anualBillableIndication: 0,
    loadingIndicators: [true, true, true, true, true, true, true, true, true],
    currentGridTitle: '',
    clearFilterGeneral: false,
    GCselectedTeam: [],
    GCselectedTeamCount: 0,
    GCteamsOnlyItems: [],
    GCselectedRZ: [],
    GCselectedRZCount: 0,
    GCRZOnlyItems: [],
    timesByUsersData: [],
    filterExportActivities: {},
    totalSearchActivitiesInRevision: null,
    SelectedDateToNumber: 0,
    SelectedTypeDateFilter: localStorage.getItem('selectedTime')
        ? localStorage.getItem('selectedTime') === 'Año'
            ? 4
            : localStorage.getItem('selectedTime') === 'Mes'
            ? 3
            : localStorage.getItem('selectedTime') === 'Semana'
            ? 2
            : localStorage.getItem('selectedTime') === 'Día'
            ? 1
            : 3
        : 3,
    SelectedDateStartIndicator: 0,
    SelectedDateEndIndicator: 0,
    SelectedYearIndicator: new Date().getFullYear(),
});
